import React from "react";
import ReactDOM from "react-dom";
import "./modal.scss";

/**********************************
 * create.portal Modal
 * exported to Nav.js
 **********************************/
export default function Modal({ openModal, children, phone }) {
  if (!openModal) return null;

  /*************************************/
  // topn is equals top: css property
  // backColor and backStyle will overwrite the background property in modal.scss if not undefined
  return ReactDOM.createPortal(
    <>
      <div className="background-modal" />
      <div className="modal-style">{children}</div>
    </>,

    document.getElementById("portal")
  );
}
// the second parameter "document.getElementById("portal")" is the DOM element located in index.html
