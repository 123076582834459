import React, { useState, useEffect} from "react";
import "../../../buttonStyle/button-style.scss";
/*******************************************
 * IntallAppButton component
 * exported to Nav.js
 *******************************************/
export default function PwaInstallation({pwa}) {
 
  const [installed, setInstalled] = useState(false);
  const buttonTitle = {
    title: "Instalar Aplicación"
  }
 

  /*************************************/
  // this execution will provide the check if pwa was installed to hide the button
  function handlerCheckInstalation() {
    const apiBoolean = window.matchMedia("(display-mode: standalone)").matches; // returns a boolean
    return apiBoolean;
  }

  // The cleanup function within the useEffect sets the isMounted variable to false,
  // indicating that the component is unmounted. This way,
  // when the component unmounts, the isMounted flag is updated,
  // and any subsequent updates or state setting inside the async function are ignored
  useEffect(() => {
    let isMounted = true;

    async function checkForInstalltion() {
      if (!installed) {
        const result = handlerCheckInstalation();
        if (isMounted) setInstalled(result);
      }
    }
    checkForInstalltion();

    return () => {
      isMounted = false;
    };
  }, [installed]);

  //  fires the promp() function from the browser
  // console.log(!(!pwaContext));
  // console.log(pwaContext);
  // console.log(installedRelatedApps());
  function installpwa() {
    // double negation will return false when is it null
    // !!pwaContext
    if (pwa) {
      pwa.prompt();
    }
  }

  let checkInstallation = !installed ? (
    <>
      <br />
      <button className="button-application-style-wrapper" onClick={installpwa}>
        {buttonTitle.title}
      </button>
    </>
  ) : null;

  /*************************************/
  return checkInstallation;
}
