import React, { useState, useEffect, lazy, Suspense } from "react";
import { Routes, Route, BrowserRouter as Router } from "react-router-dom";
import RequestAuth from "./RequestAuth";
import mobile1 from "./images/menu_icon.png";
import mobile2 from "./images/browser_menu_option.png";
import Modal from "./modules/modal/Modal.js";
import PwaInstallation from "./components/nav/application/pwaInstallation/PwaInstallation.js";
import "./app.scss";
import "../src/components/main/main.scss";
import "./components/main/main.scss";

/*************************************/
const StoreDisplay = lazy(() => import("./components/orders/StoreDisplay"));
const Application = lazy(() =>
  import("./components/nav/application/Application.js"),
);
const Login = lazy(() => import("./components/nav/login/Login.js"));
const Programs = lazy(() =>
  import("./components/members/programs/Programs.js"),
);
const OrderStatus = lazy(() =>
  import("./components/orders/checkout/checkoutComponent/OrderStatus.js"),
);
const Members = lazy(() => import("./components/members/Members.js"));
const CheckOut = lazy(() => import("./components/orders/checkout/CheckOut.js"));
const Main = lazy(() => import("./components/main/Main.js"));
const Freetrial = lazy(() =>
  import("./components/members/programs/freeTrial/FreeTrial.js"),
);
const PhysiotherapistProfile = lazy(() =>
  import("./components/members/physiotherapist/PhysiotherapistProfile.js"),
);
/*************************************
 * App
 ************************************/
export default function App() {
  const [pwaData, setPwaData] = useState(null);
  const [mobileDevice, setMobileDevice] = useState(false);
  const [installed, setInstalled] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    let isMounted = true;

    if (!pwaData && isMounted) {
      // event object that contains information about the prompt installation
      window.addEventListener("beforeinstallprompt", (event) => {
        event.preventDefault();
        console.log("before installation prompt fired");
        let installPrompt = event;
        // store event object for later use
        setPwaData(installPrompt);
      });
    }
    return () => {
      isMounted = false;
    };
  }, [pwaData]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted && !installed) {
      const apiBoolean = window.matchMedia(
        "(display-mode: standalone)",
      ).matches; // returns a boolean
      setInstalled(apiBoolean);
      setOpenModal(true);
    } else {
      setOpenModal(false);
    }
    // browser media querie
    let mediaQuerie = window.matchMedia("(max-width: 30.50em)");
    // true > 51.25em = 820px = laptop

    // adds double assurance of any bug when changing the screen size
    // bug: the change might not happened as expected if setMobileDeviceMedia is not
    // added before the function
    setMobileDevice(mediaQuerie.matches);
    const onHandlerMediaQuerie = (e) => {
      setMobileDevice(e.matches);
    };

    mediaQuerie.addEventListener("change", onHandlerMediaQuerie);

    return () => {
      mediaQuerie.removeEventListener("change", onHandlerMediaQuerie);
    };
  }, [mobileDevice, openModal, installed]);

  /*************************************/
  //  pwa event object is transfered from app to Main, MainInfo,
  // Nav, Application to PwaIndtallation component based on the hierarchy structure
  /************************************** */
  return (
    <>
      <Router>
        <Suspense
          fallback={
            <div
              style={{ width: "100%", textAlign: "center", marginTop: "1em" }}
            >
              Loading.....
            </div>
          }
        >
          <Routes>
            <Route path="/" element={<Main data={pwaData} />} />

            <Route
              path="login"
              element={
                <RequestAuth>
                  <Login />
                </RequestAuth>
              }
            />
            <Route
              path="members/freetrial"
              element={
                <RequestAuth>
                  <Freetrial />
                </RequestAuth>
              }
            />
            <Route path="orders" element={<StoreDisplay />} />
            <Route
              path="members/orders"
              element={
                <RequestAuth>
                  <StoreDisplay />
                </RequestAuth>
              }
            />
            <Route
              path="members/orders/checkout/orderstatus"
              element={
                <RequestAuth>
                  <OrderStatus />
                </RequestAuth>
              }
            />

            <Route path="aplication" element={<Application />} />
            <Route
              path="members"
              element={
                <RequestAuth>
                  <Members />
                </RequestAuth>
              }
            />

            <Route
              path="members/fisio"
              element={
                <RequestAuth>
                  <PhysiotherapistProfile />
                </RequestAuth>
              }
            />
            <Route
              path="members/programs"
              element={
                <RequestAuth>
                  <Programs />
                </RequestAuth>
              }
            />
            <Route
              path="members/orders/checkout"
              element={
                <RequestAuth>
                  <CheckOut />
                </RequestAuth>
              }
            />
          </Routes>
        </Suspense>
      </Router>
      {/* {mobileDevice ? (
        <Modal openModal={openModal}>
          <div className="appmodal">
            <PwaInstallation pwa={pwaData} />
            <h4>
              Para instalar en iPhones, es necesario realizar el procedimiento
              de forma manual; seleccione 'Añadir a la pantalla de inicio' desde
              el menú del navegador en su dispositivo móvil.
            </h4>
            <img src={mobile1} alt="mobile1" />
            <img src={mobile2} alt="mobile2" />
          </div>
        </Modal>
      ) : null} */}
    </>
  );
}
